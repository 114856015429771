<template>
	<div>
		<BaseCard :automation-id="getAutomationId('content')">
			<!-- eslint-disable-next-line vue/no-v-html -->
			<BCardText v-html="termsAndCondition"> </BCardText>
		</BaseCard>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';
import { BCardText } from 'bootstrap-vue';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT } from '@/constants/Routes.js';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import termsConditionsEn from '@/content/legal/terms-conditions-en.js';
import termsConditionsFr from '@/content/legal/terms-conditions-fr.js';
// @vue/component
@Component({
	name: 'TermsAndConditionContent',
	components: {
		BaseCard,
		BCardText
	},
	mixins: [IdMixin, BreadcrumbsManager],
	props: {
		automationId: {
			type: String,
			required: true,
			default: ''
		}
	}
})
export default class TermsAndConditionContent extends Vue {
	get termsAndCondition() {
		if (this.$store.state.i18n.locale === 'fr') return termsConditionsFr;
		return termsConditionsEn;
	}

	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.tc', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.tc', 'fr') }
			]
		);
	}
}
</script>
<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
	  "memberInfo": "Manage your account",
      "tc": "Terms and Conditions"
    }
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
	  "memberInfo": "Gérer votre compte",
      "tc": "Modalités"
    }
  }
}
</i18n>
