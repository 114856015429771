<template>
	<ManageYourAccountTemplate :page-title="$t('termsConUse')">
		<BRow>
			<BCol>
				<TermsAndConditionContent automation-id="termsAndConditions" />
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import TermsAndConditionContent from '@/components/termsAndConditions/TermsAndConditionContent';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';

// @vue/component
@Component({
	name: 'Disclaimer',
	components: {
		BRow,
		BCol,
		TermsAndConditionContent,
		ManageYourAccountTemplate
	},
	mixins: [IdMixin]
})
export default class Disclaimer extends Vue {}
</script>

<i18n>
{
  "en": {
"termsConUse": "Terms and Conditions of Use"
  },
"fr": {
"termsConUse": "Modalités d'utilisation"
}
}

</i18n>
