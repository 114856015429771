<template>
	<ManageYourAccountTemplate :page-title="$t('termsConUse')" :full-width="true">
		<BaseCard
			:title="$t('terms')"
			:automation-id="getAutomationId('terms-condition')"
			title-tag="h2"
		>
			<BCardText class="small-width mb-0">
				{{ $t('paragraph') }}
			</BCardText>
			<TermsAndConditionContentAndPrivacy />
		</BaseCard>
	</ManageYourAccountTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT } from '@/constants/Routes.js';
import { BCardText } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard';
import TermsAndConditionContentAndPrivacy from '@/components/termsAndConditions/TermsAndConditionsAndPrivacy';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';

// @vue/component
@Component({
	name: 'Disclaimer',
	components: {
		BaseCard,
		BCardText,
		TermsAndConditionContentAndPrivacy,
		ManageYourAccountTemplate
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class PrivacyAndTermsPage extends Vue {
	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.tc', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.tc', 'fr') }
			]
		);
	}
}
</script>

<i18n>
{
	"en": {
		"termsConUse": "Privacy Policy and Terms & Conditions of Use",
		"terms": "Privacy Policy and Terms & Conditions",
		"paragraph": "These contain important information about how we use your personal information, your rights, and our terms for your use of this app.",
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"tc": "Privacy, terms & conditions"
    	}
  	},
	"fr": {
		"termsConUse": "Politique de confidentialité et Conditions d'utilisation",
		"terms": "Politique de confidentialité et Conditions d'utilisation",
		"paragraph": "Celles-ci renferment des informations importantes sur la façon dont nous utilisons vos renseignements personnels et sur vos droits, de même que nos conditions quant à votre utilisation de cette application.",
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"tc": "Politique de confidentialité et Conditions d'utilisation"
    	}
 	}
}

</i18n>
